import React from 'react'
import { Link } from 'gatsby'

import { SEO } from 'Components'

const PageNotFound = () => {
  return (
    <div className="404">
      <>
        <SEO title="Page Not Found!" />
        <h1>Page Not Found!!!</h1>
        <p>
          <Link to="/">Go back...</Link>
        </p>
      </>
    </div>
  )
}

export default PageNotFound
